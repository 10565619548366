<template>
    <div>
        <v-card class="rounded-lg" elevation="0">
            <v-tabs fixed-tabs background-color="green" dark>
                <v-tabs-slider color="#086318"></v-tabs-slider>
                <v-tab>PACIENTES RECEPTORES</v-tab>

                <v-tab-item>
                    <v-card-text>
                        <v-form ref="searchReceptor">
                            <p class="space-personalized"></p>
                            <v-container>
                                <v-row>
                                    <v-col class="d-flex" cols="12" sm="10">
                                        <v-text-field color="#086318" label="Pesquisar..." append-icon="mdi-magnify"
                                            placeholder="Nome completo" outlined v-model="search" dense></v-text-field>
                                    </v-col>
                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-btn class="rounded-lg" elevation="0"
                                            @click="$router.push('/cadastrar-receptor')" color="blue">
                                            <v-icon class="mr-2" color="#FFFFFF">mdi-hand-heart</v-icon>
                                            <span class="textBtn"> Cadastrar Receptor </span>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-data-table :headers="headers" :items="receptor"
                        :footer-props="{ 'items-per-page-text': 'Receptor por Página', }" :page="page"
                        :options.sync="options" :sort-by.sync="sortBy"
                        :loading="loading">

                        <template v-slot:[`item.actions`]="{ item }" :resource="item">
                            <v-icon size="20" class="icon" color="#3fa118" align-item="center"
                                @click="$router.push({ path: '/visualizar-receptor', query: { item: item } })">mdi-eye
                            </v-icon>
                            <v-icon size="20" class="icon" color="#2ea8ff" align-item="center"
                                @click="$router.push({ path: '/editar-receptor', query: { item: item } })">mdi-pencil
                            </v-icon>
                        </template>


                        <template v-slot:[`item.cpf`]="{ item }">
                            {{ item.cpf | VMask("###.###.###-##") }}
                        </template>

                    </v-data-table>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
import axios from "@/axios";

export default {
    data() {
        return {
            dialog: false,
            search: "",
            headers: [
                {
                    text: "Nome",
                    align: "start",
                    value: "name",
                },
                {
                    text: "RGCT",
                    align: "start",
                    value: "rgct",
                },
                {
                    text: "Idade",
                    align: "start",
                    value: "age",
                },
                { text: "Ações", value: "actions", sortable: false },
            ],

            displayError: {
                display: false,
                message: [],
            },

            page: 1,
            numberOfPages: 0,
            passengers: [],
            loading: true,
            options: {},
            sortBy: "name",
            filterUnity: "",
            receptor: [],
            usuarios: [],
            unidades: [],
            hospital: [],
            message: "",
            snackbar: false,
            loadingBTN: false,
            exibirMensagem: false,
            messageError: "",

            name: "",
            cpf: "",
            email: "",
            birth: "",
            mother_name: ""
        };
    },

    components: {
    },

    created() {
        this.loading = true;

        if(this.$store.state.profile_id == 1){
          axios.get("/receiver").then((response) => {
            this.loading = false;
            this.receptor = response.data.data;
          });
        }else{
          const id = this.$store.state.unity_id
          axios.get(`/receiver/receiverUnity/${id}`).then((response) => {
            this.loading = false;
            this.receptor = response.data
          })
        }
    },

    watch: {
        sortBy: {
            handler() {

            }
        },
        options: {
            handler() {
                this.indoDataTable();
            },
        },
        deep: true,
    },

    methods: {

        //Carrega as coisas ao acessar a tela
        indoDataTable() {
            this.loading = true;
        },

        openCreateDoador() {
            this.dialog = true;
        },

        clear() {
            this.name = null,
                this.cpf = null,
                this.birth = null,
                this.mother_name = null
        },
    },

    mounted() {
        this.indoDataTable();
    },

};
</script>
<style scoped>
  .header-table {
    display: flex;
    margin-bottom: 20px;
  }

  .d-flex{
    padding: 0 12px;
  }
  
  .space {
    width: 5px;
  }

  .space-personalized{
  padding: 9px;
  }

  .spacediferent {
  width: 7px;
}
  .v-btn:not(.v-btn--round).v-size--default {
    height: 40px !important;
  }
  
  .textBtn {
    font-family: "Poppins";
    color: #ffffff;
  }
  
  .create-user-dialog {
    padding: 20px;
  }
  
  .card {
    padding: 35px;
  }
  
  .banner {
    background: #b3ffab; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #27ae60,
      #1abc9c
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #ee106d,
      #e46987
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
    border-radius: 8px;
  
    margin-bottom: 20px;
  
    display: flex;
    align-items: center;
  }


  .banner > h4 {
    color: #ffffff;
    padding: 10px;
  }
  
  .cardFooter {
    padding: 0 !important;
  }
  </style>